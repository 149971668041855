const $ = require('jquery');
import slick from 'slick-carousel';

$(function(){

'use strict';

/*******************************************
 * 
 * xxx
 * 
 *******************************************/

(function() {

    // element
    // var elm = $('.xxx__xxx');

    // // var
    
    // // init
    // function init() {
    //     if(elm.length > 0){
    //         setup();
    //     }
    // }

    // //func
    // function setup() {
    // }

    // init();

})();

/*******************************************
 *******************************************/
});